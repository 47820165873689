import React from "react";

import "../index.css";

import { Spinner } from "./Spinner";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const ReplyForm = ({ tweet, setGameState, setReply, fetchReview }) => {
  const replyRef = React.useRef(null);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    replyRef.current.focus();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        justifyContent: "space-around",
        height: "100%",
        gap: "1rem",
      }}
    >
      <div>
        <p
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          THEIR ORIGINAL TWEET
        </p>
        <div
          style={{
            height: "200px",
            backgroundColor: "#343341",
            color: "white",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          {tweet}
        </div>
      </div>
      <div>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          TYPE YOUR REPLY HERE
        </p>
        <textarea
          style={{
            height: "200px",
            backgroundColor: "#343341",
            color: "white",
            padding: "1rem",
            borderRadius: "1rem",
            width: "100%",
            resize: "none",
          }}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
          ref={replyRef}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
            transition: "all 0.5s",
            opacity: disabled ? 0.5 : 1,
            display: "flex",
            justifyContent: "center",
          }}
          onClick={async () => {
            if (disabled || loading) return;
            setLoading(true);
            setReply(replyRef.current.value);
            await fetchReview();
            setLoading(false);
            setGameState("results");
          }}
        >
          {loading ? <Spinner color="white" /> : "Submit"}
        </button>

        <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => setGameState("tweet")}
        >
          Start Again
        </button>
      </div>
    </div>
  );
};
