import React from "react";
import { TextForm } from "./TextForm";
import { ResponseScreen } from "./ResponseScreen";
import { LoadingScreen } from "./LoadingScreen";

import LogoInverted from "../images/logo-inverted.png";

export const MainPage = () => {
  // conditionally render text form , loading or results
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState(null);
  const [text, setText] = React.useState("");

  const fetchResults = async () => {
    try {
      const res = await fetch(
        "https://projecttruthisrael.com/api/interactions/generate-response",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text }),
        }
      );
      const data = await res.json();
      if (window.fathom) window.fathom.trackGoal("SZQPIHYE", 0);
      return data.interaction.response;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!text) return;
    setLoading(true);
    const response = await fetchResults();
    if (response && window.fathom) window.fathom.trackGoal("XVUYQAIA", 0);
    setResults(response);
    setText("");
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: loading ? "white" : "black",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!loading && (
        <div
          style={{ height: "5rem", display: "flex", justifyContent: "center" }}
        >
          <img src={LogoInverted} style={{ height: "100%", width: "10rem" }} />
        </div>
      )}
      {results ? (
        <ResponseScreen results={results} setResults={setResults} />
      ) : loading ? (
        <LoadingScreen />
      ) : (
        <TextForm
          setText={setText}
          handleSubmit={handleSubmitForm}
          text={text}
        />
      )}
    </div>
  );
};
