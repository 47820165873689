import React from "react";

import "../index.css";

export const TextForm = ({ setText, text, handleSubmit }) => {
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        backgroundColor: "black",
        height: "100%",
        color: "white",
        display: "flex",
        flexDirection: "column",
        padding: "3rem",
        gap: "2rem",
      }}
    >
      <div style={{ textAlign: "center" }}>
        {
          "Paste the text from any tweet that you want to fact-check into the box below and we’ll provide you with a direct response:"
        }
      </div>
      <div style={{ fontSize: "0.7rem", textAlign: "center" }}>
        (NOTE: THIS AI TOOL WORKS IN ANY LANGUAGE)
      </div>
      <textarea
        onChange={(e) => setText(e.target.value)}
        type="text"
        style={{
          borderRadius: "1rem",
          height: "70%",
          resize: "none",
          padding: "1rem",
        }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          style={{
            backgroundColor: text ? "white" : "grey",
            color: "black",
            opacity: text ? "1" : "0.5",
            borderWidth: "0",
            borderRadius: "3rem",
            padding: "1rem",
            width: "60%",
            transition: "opacity 0.5s ease",
          }}
        >
          CONTINUE
        </button>
      </div>
    </form>
  );
};
