import React from "react";
import Logo from "../images/logo.png";
import Loader from "../images/loading.gif";

export const LoadingScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "7rem 2rem 3% 2rem ",
        gap: "6rem",
        alignItems: "center",
        flexGrow: "1",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={Logo} style={{ width: "100%" }} />
      </div>
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          fontSize: "1.3rem",
        }}
      >
        {"Please wait a moment. \n A response is being generated."}
      </div>
      <img src={Loader} style={{ height: "5rem" }} />
    </div>
  );
};
