import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Modal from "react-modal";
import "./index.css";

import { MainPage } from "./components/MainPage";
import { LandingPage } from "./components/LandingPage";
import { GamePage } from "./components/GamePage";

Modal.setAppElement('#root')

const OverlayModal = () => (
  <Modal
    isOpen={true}
    style={{
      content: {
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontSize: "1.2em",
        fontFamily: "'Arial', sans-serif",
        padding: "1em",
        boxSizing: "border-box",
        position: "absolute",
        top: "10%",
        left: "10%",
        right: "10%",
        bottom: "10%",
        border: "none",
        background: "transparent",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "0",
        outline: "none",
        padding: "20px",
        maxWidth: "80%",
      },
    }}
  >
    Due to a malicious cyber attack by anti-Israel hacktivists, this website is
    no longer functional.
    <br />
    Please email hello@projecttruthisrael.com for more information.
  </Modal>
);
function App() {
  const hasVisitedBefore = () => {
    return localStorage.getItem("visited") === "true";
  };

  const setVisited = () => {
    localStorage.setItem("visited", "true");
  };

  React.useEffect(() => {
    if (!hasVisitedBefore()) {
      window.location.href = "/landing";
      setVisited();
    }
  }, []);

  return (
    <BrowserRouter>
      <OverlayModal />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/game" element={<GamePage />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;