import React from "react";

import "../index.css";

const ScoreCircle = ({ filled }) => {
  return (
    <div
      style={{
        width: "0.7rem",
        height: "0.7rem",
        borderRadius: "50%",
        backgroundColor: filled ? "black" : "#d9d9d9",
      }}
    />
  );
};

const Score = ({ title, score }) => {
  const renderCircles = () => {
    let circles = [];
    for (let i = 0; i < 5; i++) {
      if (i < score) {
        circles.push(<ScoreCircle filled key={i} />);
      } else {
        circles.push(<ScoreCircle key={i} />);
      }
    }
    return circles;
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{title}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "7rem",
        }}
      >
        {renderCircles()}
      </div>
    </div>
  );
};

export const ScorePage = ({ setGameState, scores }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        justifyContent: "space-around",
        height: "100%",
        gap: "1rem",
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        {"Hey, good going! \n Here’s your score."}
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10rem",
            width: "10rem",
            padding: "0 2rem 0 2rem",
            backgroundColor: "#d9d9d9",
            fontSize: "6rem",
            fontWeight: "bold",
          }}
          id="score-number"
        >
          {scores.total || 0}
        </div>
      </div>
      <p style={{ textAlign: "center" }}>
        {
          "YOUR SCORE IS BASED ON THE QUALITY OF YOUR REPLY. THE HIGHEST POSSIBLE SCORE IS “10”. THE FOLLOWING COMPONENTS HAVE BEEN REFLECTED IN YOUR PERSONAL SCORE:"
        }
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          padding: "0 3rem 0 3rem",
        }}
      >
        <Score title="TRUTHFULNESS" score={scores.TRUTHFULNESS} />
        <Score title="COHESIVENESS" score={scores.COHESIVENESS} />
        <Score title="CREDIBILITY" score={scores.CREDIBILITY} />
        <Score title="COMPLEXITY" score={scores.COMPLEXITY} />
        <Score title="EMOTION" score={scores.EMOTION} />
      </div>
      <div
        style={{
          backgroundColor: "#d9d9d9",
          textAlign: "center",
          padding: "1rem",
        }}
        onClick={() => setGameState("tips")}
      >
        TO VIEW TIPS THAT CAN HELP YOU SCORE HIGHER PLEASE CLICK HERE TO
        CONTINUE
      </div>
    </div>
  );
};
