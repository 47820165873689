import React from "react";

import "../index.css";

import CopyIcon from "../images/copy-icon.svg";

export const ResponseScreen = ({ results, setResults }) => {
  const [textCopied, setTextCopied] = React.useState(false);

  const textAreaRef = React.useRef(null);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textAreaRef.current.value);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
        color: "white",
        display: "flex",
        flexDirection: "column",
        padding: "3rem",
        gap: "2rem",
      }}
    >
      <div style={{ textAlign: "center" }}>
        {"Copy and paste the text below as your direct response:"}
      </div>
      <div
        style={{
          height: "70%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          transition: "all 0.5s ease",
        }}
      >
        {navigator.clipboard && (
          <button
            style={{
              position: "absolute",
              bottom: "10px",
              alignSelf: "center",
              opacity: "0.7",
              borderRadius: "3rem",
              border: "0",
              height: "2rem",
              padding: "0.5rem",
              gap: "0.5rem",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              color: "black",
              transition: "all 0.5s ease",
            }}
            onClick={(e) => {
              if (textCopied) return;
              setTextCopied(true);
              copyToClipboard();
              setTimeout(() => setTextCopied(false), 4000);
            }}
            id="copy-button"
          >
            <img
              src={CopyIcon}
              style={{
                height: "1rem",
                opacity: textCopied ? "0.5" : "1",
                transition: "all 0.5s ease",
              }}
            />
            <p
              style={{
                opacity: textCopied ? "0.5" : "1",
              }}
            >
              {textCopied ? "Response Copied!" : "Copy Response"}
            </p>
          </button>
        )}
        <textarea
          type="text"
          style={{
            borderRadius: "1rem",
            backgroundColor: "white",
            flexGrow: "1",
            width: "100%",
            height: "100%",
            color: "black",
            resize: "none",
            padding: "1rem",
          }}
          defaultValue={results}
          ref={textAreaRef}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <button
          style={{
            backgroundColor: "white",
            color: "black",
            borderWidth: "0",
            borderRadius: "3rem",
            padding: "1rem",
            width: "60%",
          }}
          onClick={() => setResults(null)}
        >
          START AGAIN
        </button>
        <div style={{ textAlign: "center", fontSize: "0.8rem" }}>
          WANT TO CONNECT? PLEASE SEND AN EMAIL TO{" "}
          <a
            className="contact-link"
            href="mailto:hello@projecttruthisrael.com"
          >
            hello@projecttruthisrael.com
          </a>
        </div>
      </div>
    </div>
  );
};
