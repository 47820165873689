import React from "react";
import "../index.css";

export const InflamTweet = ({ tweet, setGameState }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        padding: "1.5rem",
        gap: "3rem",
      }}
    >
      <h1
        className="blinker-font"
        style={{
          padding: "0 1.5rem",
          fontWeight: "bold",
          fontSize: "2.5rem",
          width: "100%",
          alignSelf: "flex-start",
        }}
      >
        Ready to start your virtual debate and defend Israel?
      </h1>
      <p style={{ padding: "0 1.5rem" }}>
        The following tweet was sent to you as a pro-Palestinian, anti-Israel
        rant. Please read it carefully and then click reply to give your best
        possible answer.
      </p>
      <div
        style={{
          height: "200px",
          backgroundColor: "#343341",
          color: "white",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        {tweet}
      </div>
      <button
        style={{
          backgroundColor: "#343341",
          color: "white",
          padding: "0.5rem",
          width: "80%",
          border: "none",
          fontWeight: "bold",
        }}
        onClick={() => setGameState("reply")}
      >
        Reply
      </button>
    </div>
  );
};
