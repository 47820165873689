import React from "react";
import Logo from "../images/logo.png";
import { useNavigate } from "react-router";

import "../index.css";

export const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "7% 2rem 3% 2rem ",
        // gap: "4rem",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={Logo} style={{ width: "100%" }} />
      </div>
      <div style={{ textAlign: "center", fontSize: "0.9rem" }}>
        {"[TIMELY RESPONSES FOR UNBIASED TRANSPARENCY AND HONESTY]"}
      </div>
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          fontSize: "1.3rem",
        }}
      >
        We created this free AI-powered, fact-checking, pro-Israel app to help
        counterbalance misinformation on social media and allow you to champion
        the truth in defense of the State of Israel.{" "}
      </div>
      <div style={{ fontSize: "0.7rem", textAlign: "center" }}>
        NOTE: THIS APP USES AI TECHNOLOGY AND ALL REPLIES SHOULD BE CAREFULLY
        REVIEWED BEFORE POSTING
      </div>
      <div style={{ fontSize: "0.7rem", textAlign: "center" }}>
        NOTE: THIS AI TOOL WORKS IN ANY LANGUAGE
      </div>
      <button
        style={{
          width: "50%",
          color: "white",
          backgroundColor: "black",
          height: "3rem",
          borderRadius: "3rem",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        {" "}
        GET STARTED
      </button>
    </div>
  );
};
