import React from "react";

import "../index.css";

export const TipsPage = ({
  tips = [
    "lorem ipsum set amit ;kqwvrf ingcrgf iuawgr nfciuw grfciwurnfiwg fiwey grf cni uwgfnciwufg",
    "lorem ipsum set amit  ;kqwvrf ingcrgf iuawgr nfciuw grfciwurnfiwg fiwey grf cni uwgfnciwufg",
    "lorem ipsum set amit ;kqwvrf ingcrgf iuawgr nfciuw grfciwurnfiwg fiwey grf cni uwgfnciwufg",
  ],
  setGameState,
}) => {
  return (
    <div
      style={{
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
        height: "100%",
        alignItems: "center",
      }}
    >
      <h1>Here are some handy tips for next time.</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {tips.map((tip, i) => {
          return (
            <div key={i}>
              <p style={{ textAlign: "center" }}>{`Tip ${i + 1}.`}</p>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                {tip}
              </p>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => setGameState("tweet")}
        >
          New Tweet
        </button>
        <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => setGameState("about")}
        >
          About
        </button>
      </div>
    </div>
  );
};
