import React from "react";

import "../index.css";

import { InflamTweet } from "./InflamTweet";
import { ReplyForm } from "./ReplyForm";
import { GameResponse } from "./GameResponse";
import { ScorePage } from "./ScorePage";
import { TipsPage } from "./TipsPage";
import { AboutPage } from "./AboutPage";

import GameLogo from "../images/isr_game_logo.png";

const inflamTweets = [
  "How can anyone support a nation that commits such atrocities against innocent children? Israel's actions are indefensible. #GazaUnderAttack #SavePalestinianChildren",
  "Israel's occupation of Palestinian land is a blatant violation of human rights! The world needs to wake up and see the truth. #FreePalestine #Occupation",
  "Boycott Israel! Stand against apartheid and stand with the oppressed. It's time to show where we stand. #BDS #EndApartheid",
  "Every day Palestinians live under Israeli oppression. Their voices are silenced, and their homes are destroyed. Where's the justice? #PalestinianLivesMatter #StopTheSiege",
  "The international community turns a blind eye to Israel's crimes. It's on us to raise awareness and fight for justice! #HumanRightsViolations #StandWithPalestine",
];

export const GamePage = () => {
  const [gameState, setGameState] = React.useState("tweet");
  const [tweet, setTweet] = React.useState("");

  const [reply, setReply] = React.useState("");
  // "IDIOTS! Maybe if Palestinians stopped celebrating terrorists killing people, they'd have a better life! Ever think of that? #MuslimsKill"

  const [suggestedReply, setSuggestedReply] = React.useState("");
  // "Let's get the facts straight. Israel withdrew entirely from Gaza in 2005. Failed Palestinian leadership choices impact the situation more than you admit. Real peace requires two sides willing to negotiate. #FactsMatter #SeekingPeace"

  const [tips, setTips] = React.useState([]);

  const [scores, setScores] = React.useState({
    total: 0,
    TRUTHFULNESS: 0,
    COHERENCE: 0,
    CREDIBILITY: 0,
    COMPLEXITY: 0,
    EMOTION: 0,
  });

  const [showBackButton, setShowBackButton] = React.useState(false);

  const chooseRandomTweet = () => {
    let tweet;
    const index = Math.trunc(Math.random() * inflamTweets.length);
    setTweet(inflamTweets[index]);
  };
  const fetchReview = async () => {
    try {
      const res = await fetch(
        "https://project-truth-game-backend.oran-shai.workers.dev/api/interactions/generate-response",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: JSON.stringify({ TWEET: tweet, RESPONSE: reply }),
          }),
        }
      );
      const data = await res.json();

      setSuggestedReply(data.response.BETTER);
      setScores({
        total: data.response.SCORE,
        TRUTHFULNESS: data.response.TRUTHFULNESS,
        COHERENCE: data.response.COHERENCE,
        CREDIBILITY: data.response.CREDIBILITY,
        COMPLEXITY: data.response.COMPLEXITY,
        EMOTION: data.response.EMOTION,
      });
      setTips(data.response.TIPS);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (gameState === "score" || gameState === "tips") setShowBackButton(true);
    else setShowBackButton(false);
  }, [gameState]);

  React.useEffect(() => {
    chooseRandomTweet();
  }, []);

  const renderGameState = () => {
    switch (gameState) {
      case "tweet":
        return <InflamTweet tweet={tweet} setGameState={setGameState} />;
      case "reply":
        return (
          <ReplyForm
            setReply={setReply}
            tweet={tweet}
            setGameState={setGameState}
            fetchReview={fetchReview}
          />
        );
      case "results":
        return (
          <GameResponse
            reply={reply}
            setGameState={setGameState}
            suggestedReply={suggestedReply}
          />
        );
      case "score":
        return <ScorePage setGameState={setGameState} scores={scores} />;
      case "tips":
        return <TipsPage setGameState={setGameState} tips={tips} />;
      case "about":
        return <AboutPage setGameState={setGameState} />;
      default:
        return <div>404. Not Found</div>;
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {gameState !== "about" && (
        <div
          style={{
            display: "flex",
            justifyContent: showBackButton ? "space-between" : "flex-end",
            padding: "2rem 2rem 0 2rem",
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              cursor: "pointer",
              alignSelf: "flex-start",
              height: "fit-content",
              display: showBackButton ? "flex" : "none",
              borderRadius: "50%",
            }}
            onClick={() => {
              if (gameState === "score") {
                setGameState("results");
                return;
              }
              if (gameState === "tips") {
                setGameState("score");
                return;
              }
            }}
          >
            ←
          </div>
          <img src={GameLogo} />
        </div>
      )}
      <div style={{ flex: 1 }}>{renderGameState()}</div>
    </div>
  );
};
