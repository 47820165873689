import React from "react";

import "../index.css";

import LargeGameLogo from "../images/isr_game_logo_large.png";

export const AboutPage = ({ setGameState }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2rem 1.5rem",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          cursor: "pointer",
          alignSelf: "flex-start",
        }}
        onClick={() => setGameState("tips")}
      >
        ←
      </div>
      <img src={LargeGameLogo} style={{ width: "80%" }} />
      <div
        style={{
          display: " flex",
          flexDirection: "column",
          gap: "2rem",
          padding: "1.5rem",
          color: " gray",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          A New Social Media Gaming Platform That Educates and Inspires Today’s
          Jewish Youth
        </p>
        <p>
          The Israel Games was created to educate, inform, and train teenagers,
          college students, and adults about crafting pro-Israel narratives
          through a social media-style gaming experience.
        </p>
        <p>
          We believe this offers a unique and impactful way to educate and
          empower Jewish youth. By leveraging a format they're familiar with,
          this game can play a crucial role in fostering informed and articulate
          advocates for Israel.
        </p>
        <p
          style={{
            fontSize: "0.7rem",
            textAlign: "center",
            color: "lightgray",
          }}
        >
          Got questions or need help?{" "}
          <a
            style={{ color: "black", fontWeight: "bold" }}
            href="mailto:hello@projecttruthisrael.com"
          >
            Email Us!
          </a>
        </p>
      </div>
    </div>
  );
};
