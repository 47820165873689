import React from "react";

export const GameResponse = ({ reply, setGameState, suggestedReply }) => {
  const [showSuggestedReply, setShowSuggestedReply] = React.useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        justifyContent: "space-around",
        gap: "1rem",
        height: "100%",
      }}
    >
      <div>
        <p
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          YOUR REPLY
        </p>
        <div
          style={{
            height: "200px",
            backgroundColor: "#343341",
            color: "white",
            padding: "1rem",
            borderRadius: "1rem",
          }}
        >
          {reply}
        </div>
      </div>
      <div>
        <p
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          SUGGESTED REPLY
        </p>
        <div
          style={{
            height: "200px",
            backgroundColor: "#343341",
            color: "white",
            padding: "1rem",
            borderRadius: "1rem",
            textAlign: showSuggestedReply ? "left" : "center",
            overflow: "scroll",
          }}
          onClick={() => {
            if (!showSuggestedReply) setShowSuggestedReply(true);
          }}
        >
          {showSuggestedReply ? (
            suggestedReply
          ) : (
            <div style={{ border: "1px solid grey", borderRadius: "2rem" }}>
              Click to view suggested reply
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        {/* <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => setGameState("tweet")}
        >
          Continue Playing
        </button> */}
        <button
          style={{
            backgroundColor: "#343341",
            color: "white",
            padding: "0.5rem",
            width: "80%",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => setGameState("score")}
        >
          Show My Score
        </button>
      </div>
    </div>
  );
};
